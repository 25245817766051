import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import type { LocationItem } from '@/services/listingDataService/types/locations'
import type { Cluster } from '@/services/strapiService/types'
import LocationsBuilder from '@/services/listingDataService/builders/locations-builder'
import { applyCustomRules, groupLocations } from '@/stores/locationsStore/utils'
import * as legacyZonesUtils from '@/utils/locations/zones'

export const useLocationsStore = defineStore('locationsStore', () => {
  const { $sobreplanosDataService, $getAvailableClusters } = useNuxtApp()
  const { locale } = useI18n()
  const route = useRoute()
  const logger = useLogger()

  const rawLocations = ref<LocationItem[]>([])
  const isLoading = ref(false)
  const error = ref('')

  const hasError = computed(() => error.value !== null)
  const areLocationsLoaded = computed(() => rawLocations.value.length > 0)

  const shouldLoadClusters = computed(() => !!route.query.clusters)

  const groupedLocations = computed(() => {
    if (!areLocationsLoaded.value) return { business_hubs: [] }

    return applyCustomRules(locale.value, groupLocations(rawLocations.value))
  })

  const initializeLocations = async () => {
    isLoading.value = true

    try {
      let clusters: Cluster[] = []
      const locationsResponse = await $sobreplanosDataService.getAvailableLocations()

      if (shouldLoadClusters.value) {
        clusters = await $getAvailableClusters(locale.value)
      }

      const locationsBuilt = LocationsBuilder.build(locationsResponse.rows, { clusters })

      rawLocations.value = locationsBuilt
      error.value = ''
    } catch (err: any) {
      error.value = err?.message || 'Error initializing locations'

      logger.error({
        message: 'Error initializing locations',
        stack: err
      })
    } finally {
      isLoading.value = false
    }
  }

  const getAllAvailableBusinessHubs = () => legacyZonesUtils.getAllAvailableBusinessHubs(locale.value, { [locale.value]: groupedLocations.value })

  const getBusinessHubsByValues = (hubValues: string[]) => legacyZonesUtils.getBusinessHubsByValues(hubValues, locale.value, { [locale.value]: groupedLocations.value })

  const getBusinessHubsByNeighborhoodsValues = (neighborhoodsValues: string[]) => legacyZonesUtils.getBusinessHubsByNeighborhoodsValues(neighborhoodsValues, locale.value, { [locale.value]: groupedLocations.value })

  const getBusinessHubZonesByNeighborhoodsValues = (hubValue: string, neighborhoodsValues: string[]) => legacyZonesUtils.getBusinessHubZonesByNeighborhoodsValues(hubValue, neighborhoodsValues, locale.value, { [locale.value]: groupedLocations.value })

  const getZonesByBusinessHubValue = (hubValue: string) => legacyZonesUtils.getZonesByBusinessHubValue(hubValue, locale.value, { [locale.value]: groupedLocations.value })

  const getZonesByNeighborhoodsValues = (neighborhoodsValues: string[]) => legacyZonesUtils.getZonesByNeighborhoodsValues(neighborhoodsValues, locale.value, { [locale.value]: groupedLocations.value })

  const getAllAvailableNeighborhoods = () => legacyZonesUtils.getAllAvailableNeighborhoods(locale.value, { [locale.value]: groupedLocations.value })

  const getNeighborhoodsByValues = (neighborhoodValues: string[]) => legacyZonesUtils.getNeighborhoodsByValues(neighborhoodValues, locale.value, { [locale.value]: groupedLocations.value })

  const getNeighborhoodsFromBusinessHubValue = (hubValue: string) => legacyZonesUtils.getNeighborhoodsFromBusinessHubValue(hubValue, locale.value, { [locale.value]: groupedLocations.value })

  return {
    rawLocations,
    isLoading,
    error,
    hasError,
    areLocationsLoaded,
    shouldLoadClusters,
    groupedLocations,
    initializeLocations,
    locationsQueryUtils: {
      getAllAvailableBusinessHubs,
      getBusinessHubsByValues,
      getBusinessHubsByNeighborhoodsValues,
      getBusinessHubZonesByNeighborhoodsValues,
      getZonesByBusinessHubValue,
      getZonesByNeighborhoodsValues,
      getAllAvailableNeighborhoods,
      getNeighborhoodsByValues,
      getNeighborhoodsFromBusinessHubValue
    }
  }
})
